import request from '@/plugins/axios'

// 工作台信息 
export const apiWorkbenchIndex = () => request.get('/workbench/index')

// 商品列表 
export const apiWorkbenchTopAmount = (params: any) => request.get('workbench/topAmount', params)

// 用户列表 
export const apiWorkbenchTopUser = (params: any) => request.get('/workbench/topUser', params)

// 检测更新
export const apiCheckRefresh = (params?: any) => request.get('/workbench/checkVersion', params)

